import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import { TagEventTracker } from 'components/TagEventTracker';

import { nl2br } from '../../utils/functions';
import { ContactProps } from './models';

import './Contact.scss';

const Contact: FC<{ data: ContactProps }> = ({
  data: {
    contact: {
      urls,
      seo,
      title,
      description,
      imageDesktop,
      imageMobile,
      imageLetter,
      subtitle,
      contact,
      headband,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const location = useLocation();

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <TagEventTracker eventType="page_view" data={{ pageTemplate: location.pathname }}>
        <div className="contact">
          <div className="contact__banner">
            <Image imageData={imageDesktop} alt="" className="d-none d-md-block" />
            <Image imageData={imageMobile} alt="" className="d-block d-md-none" />
            <div className="contact__banner-text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col text-center text-md-start">
                    <h2 className="mb-0 mb-4">{title}</h2>
                    <DangerouslySetInnerHtml
                      html={nl2br(description)}
                      element="p"
                      className="ms-0 ms-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact__subtitle text-center pt-5">
            <DangerouslySetInnerHtml html={nl2br(subtitle)} element="h4" />
          </div>
          <div className="contact__letter text-center">
            <Image imageData={imageLetter} alt={imageLetter.altText} />
            <div className="contact__letter-text">
              <DangerouslySetInnerHtml html={contact} element="div" />
            </div>
          </div>
        </div>
      </TagEventTracker>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    contact(url: { eq: $url }) {
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      title
      description
      imageDesktop {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
      imageLetter {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 785)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 785)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 785)
          }
        }
      }
      subtitle
      contact
    }
  }
`;

export default Contact;
